<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm @submit.prevent="register()" method="POST">
                <h1>Buat Akun Baru</h1>
                <CInput
                  :invalid-feedback="validator.name"
                  :is-valid="validator.name ? false : true"
                  v-model="form.name"
                  placeholder="Nama"
                  type="text"
                >
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput>
                <CInput
                  :invalid-feedback="validator.phone_number"
                  :is-valid="validator.phone_number ? false : true"
                  v-model="form.phone_number"
                  placeholder="Nomor HP"
                  type="number"
                >
                  <template #prepend-content
                    ><CIcon name="cil-screen-smartphone"
                  /></template>
                </CInput>
                <CInput
                  :invalid-feedback="validator.email"
                  :is-valid="validator.email ? false : true"
                  v-model="form.email"
                  placeholder="Email"
                  autocomplete="email"
                  prepend="@"
                />
                <CInput
                  :invalid-feedback="validator.password"
                  :is-valid="validator.password ? false : true"
                  v-model="form.password"
                  placeholder="Password"
                  type="password"
                  autocomplete="new-password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CInput
                  :invalid-feedback="validator.password_confirmation"
                  :is-valid="validator.password_confirmation ? false : true"
                  v-model="form.password_confirmation"
                  placeholder="Repeat password"
                  type="password"
                  autocomplete="new-password"
                  class="mb-4"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CButton type="submit" color="success" block>Buat Akun</CButton>
              </CForm>
            </CCardBody>
            <CCardFooter class="p-4">
              <CRow>
                <CCol>
                  <router-link to="/auth/login">
                    <CButton block variant="outline" color="primary">
                      Sudah Punya akun? Login
                    </CButton>
                  </router-link>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from "../../apis/api";
export default {
  name: "Register",
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
      },
      validator: {
        name: "",
        email: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    resetValidator() {
      this.validator = {
        name: "",
        email: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
      };
    },
    async register() {
      this.resetValidator();
      try {
        await axios.post("/user", this.form);
        this.$notify({
          text: "Akun berhasil dibuat, Silahkan login untuk melanjutkan",
          type: "success",
        });
        this.$router.push("/auth/login");
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.name) {
            this.validator.name = errors.name.join(" & ");
          }
          if (errors.email) {
            this.validator.email = errors.email.join(" & ");
          }
          if (errors.password) {
            this.validator.password = errors.password.join(" & ");
          }
          if (errors.password_confirmation) {
            this.validator.password_confirmation =
              errors.password_confirmation.join(" & ");
          }
          if (errors.phone_number) {
            this.validator.phone_number = errors.phone_number.join(" & ");
          }
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
    },
  },
};
</script>
